import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';

import i18n from 'weego-common/src/hoc/i18n';

import PhoneNumberInput from '../common/PhoneNumberInput';
import colors from '../../theme/colors';

class AuthForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
    };
  }

  handlePhoneChange = phoneNumber => {
    const { onPhoneChange } = this.props;
    this.setState({
      phoneNumber,
    });
    onPhoneChange(phoneNumber);
  };

  connection = () => {
    const { onSubmit } = this.props;
    const sending = true;
    onSubmit(sending);
  };
  render() {
    const { phoneNumber } = this.state;
    const { t } = this.props;
    return (
      <div style={styles.container}>
        <div>
          <PhoneNumberInput
            value={phoneNumber}
            onChange={this.handlePhoneChange.bind(this)}
          />
          <Button
            style={styles.buttonConnect}
            onClick={this.connection.bind(this)}
            secondary
            size="large"
            color={colors.PRIMARY}
          >
            {t('Se connecter')}
          </Button>
        </div>
      </div>
    );
  }
}

const styles = {
  container: { width: '100%' },
  phone: {
    color: colors.LOGIN_GREY,
  },
  buttonConnect: {
    width: '100%',
    background: colors.PRIMARY,
    marginTop: 10,
  },
};

export default i18n('auth')(AuthForm);
