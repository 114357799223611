import { connect } from 'react-redux';
import compose from 'recompose/compose';
import toArray from 'lodash/toArray';

import TripsPlanningScreen from './TripsPlanningScreen';
import tripActions from '../../../actions/trip';
import vehiclesActions from '../../../actions/vehicles';
import driversActions from '../../../actions/drivers';

const mapStateToProps = state => ({
  editing: state.trip.editing,
  loading: state.trip.loading,
  trips: state.trip.trips,
  creating: state.trip.creating,
  error: state.trip.error,
  drivers: toArray(state.drivers),
  vehicles: toArray(state.vehicles),
});

const mapDispatchToProps = dispatch => ({
  clearTrips: compose(dispatch, tripActions.clearTrips),
  requestTrips: compose(dispatch, tripActions.requestTrips),
  createTrip: compose(dispatch, tripActions.createTrip),
  editTrip: compose(dispatch, tripActions.editTrip),
  requestTripsInPeriod: compose(dispatch, tripActions.requestTripsInPeriod),
  requestDrivers: compose(dispatch, driversActions.fetchStart),
  requestVehicles: compose(dispatch, vehiclesActions.fetchStart),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

const TripsPlanningContainer = enhance(TripsPlanningScreen);

export default TripsPlanningContainer;
