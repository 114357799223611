import { connect } from 'react-redux';
import compose from 'recompose/compose';
import omit from 'lodash/omit';

import Trip from './Trip';
import tripActions from '../../../actions/trip';
import notificationActions from '../../../actions/notification';

const mapStateToProps = state => ({
  deleting: state.trip.deleting,
  editing: state.trip.editing,
  creating: state.trip.creating,
  error: state.trip.error,
  loading: state.notification.loading,
  notificationError: state.notification.error,
});

const mapDispatchToProps = dispatch => ({
  deleteTrip: compose(dispatch, tripActions.deleteTrip),
  editTrip: compose(dispatch, tripActions.editTrip),
  requestTrips: compose(dispatch, tripActions.requestTrips),
  sendNotification: compose(dispatch, notificationActions.sendNotification),
  requestNotificationsList: compose(
    dispatch,
    notificationActions.requestNotificationsList,
  ),
  createTrip: compose(dispatch, tripActions.createTrip),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToProps));

const TripContainer = enhance(Trip);

TripContainer.propTypes = omit(
  Trip.propTypes,
  'creating',
  'editing',
  'deleting',
  'error',
  'loading',
  'notificationError',
  'deleteTrip',
  'editTrip',
  'requestTrips',
  'sendNotification',
  'createTrip',
);

export default TripContainer;
