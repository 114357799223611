import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Input, Dropdown, Menu, Icon, Button } from 'semantic-ui-react';
import { ReactSVG } from 'react-svg';
import debounce from 'lodash/debounce';
import uniqBy from 'lodash/uniqBy';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import isArray from 'lodash/isArray';
import intersection from 'lodash/intersection';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import moment from 'moment-timezone';
import memoize from 'memoize-one';
import Fuse from 'fuse.js';

import client from '../../../providers/weego-client';

import parseDateRangeString from '../../../utils/date/parseDateRangeString';
import formatDateRange from '../../../utils/date/formatDateRange';

import VehiclesMap from '../../../components/liveMap/VehiclesMap';
import UpcomingTripsSummary from '../../../components/trips/UpcomingTripsSummary';
import TripsTimelineView from '../../../components/trips/TripsTimelineView';
import DialogBox from '../../../components/common/DialogBox';
import TripDetails from '../../../components/trips/TripDetails';

import tableColumnIcon from '../../../assets/images/table-column.svg';
import mapIcon from '../../../assets/images/map.svg';

import './Dashboard.css';
import TimeRangeInput from '../../../components/common/TimeRangeInput';
import tripPropType from '../../../components/trips/tripPropType';
import driverLabel from 'weego-common/src/utils/driverLabel';
import vehicleLabel from 'weego-common/src/utils/vehicleLabel';
import withi18n from 'weego-common/src/hoc/i18n';
import TripNotifications from '../../../components/trips/TripNotifications';
import { fetchSavedFilters, saveFilters } from '../../../utils/persistFilters';

const FILTER_SCOPE = '_dashboard';

const initialDateRange = [
  moment().startOf('day').toDate(),
  moment().add(1, 'day').endOf('day').toDate(),
];
const initialTimeRange = [
  moment().startOf('day').toDate(),
  moment().endOf('day').toDate(),
];

const getTripsWithSearchProperties = memoize(trips =>
  trips.map(trip => ({
    ...trip,
    driver: {
      ...trip.driver,
      fullName: trip.driver?.firstname + ' ' + trip.driver?.lastname,
    },
    demands: trip.demands?.map(demand => ({
      ...demand,
      passenger: {
        ...demand.passenger,
        fullName:
          demand.passenger?.firstName + ' ' + demand.passenger?.lastName,
      },
    })),
  })),
);

const filterOutHiddenVehiclesDrivers = memoize(trips =>
  trips?.filter(t => !t.driver?.hideInDashboard && !t.vehicle?.hideInDashboard),
);

const filterTripsByMatcher = memoize((trips, matcher) =>
  trips.filter(trip =>
    Object.keys(matcher).every(
      k =>
        trip[k] === matcher[k] ||
        (isArray(trip[k]) && trip[k].includes(matcher[k])) ||
        (isArray(trip[k]) && intersection(trip[k], matcher[k]).length !== 0),
    ),
  ),
);

const filterTripsByTimeRange = memoize((trips, timeRange) =>
  trips.filter(trip => {
    const departureTime = moment(trip.departureTime);
    const start = moment(timeRange[0]).set({
      year: departureTime.year(),
      month: departureTime.month(),
      date: departureTime.date(),
    });
    const end = moment(timeRange[1]).set({
      year: departureTime.year(),
      month: departureTime.month(),
      date: departureTime.date(),
    });
    return departureTime.isBetween(start, end, 'minutes', '[]');
  }),
);

const filterB2CTrips = memoize(trips =>
  trips.filter(trip => {
    const { demands } = trip;
    if (!demands) {
      return false;
    }
    if (demands.find(d => !d.companyId)) {
      return true;
    }
    return false;
  }),
);

const FUSE_OPTIONS = {
  keys: [
    'from.name',
    'to.name',
    'stops.name',
    'driver.fullName',
    'vehicle.carLabel',
    'vehicle.carNumber',
    'demands.passenger.fullName',
    'driver.phone',
    'demands.passenger.phoneNumber',
    'demands.passenger.clientSuppliedId',
  ],
  threshold: 0.3,
  distance: 500,
};

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    const filters = fetchSavedFilters();
    const { matcher, b2cOnly, timeRange } = filters?.[FILTER_SCOPE] || {};
    this.state = {
      openTrip: null,
      tripDetailsOpen: false,
      tripNotificationsOpen: false,
      viewMode: 'MAP',
      search: '',
      searching: false,
      dateRangeString: formatDateRange(initialDateRange),
      dateRange: initialDateRange,
      timeRange: timeRange || initialTimeRange,
      matcher: matcher || {},
      b2cOnly: !!b2cOnly,
      autoRefresh: true,
    };
  }
  fuse = null;

  async componentDidMount() {
    const { t } = this.props;
    document.title = t('Tableau de bord');

    if (!(await client.auth.isLoggedIn())) {
      window.location.replace('/auth');
    }
    this.refreshHeaderContent();
    const { fetchGroups } = this.props;
    fetchGroups();
    this.refreshTrips();
    this.refreshInterval = setInterval(() => {
      if (this.state.autoRefresh) {
        this.refreshTrips(false);
      }
    }, 60 * 1000);
  }

  componentDidUpdate(prevProps, prevState) {
    const { trips } = this.props;
    const { dateRange } = this.state;
    if (trips !== prevProps.trips) {
      this.refreshHeaderContent();
      this.fuse = new Fuse(getTripsWithSearchProperties(trips), FUSE_OPTIONS);
    }
    if (dateRange !== prevState.dateRange) {
      this.refreshTrips();
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  refreshTrips(clear = true) {
    const { dateRange, viewMode } = this.state;
    const [start, end] = dateRange;
    if (!start || !end) {
      return;
    }
    const { unfocusTrip, clearTrips, requestTripsInPeriod } = this.props;
    if (clear) {
      unfocusTrip();
      clearTrips();
    }
    requestTripsInPeriod(
      {
        start,
        end,
      },
      {
        include: [
          'driver',
          'vehicle',
          {
            relation: 'demands',
            scope: {
              isDeleted: true,
              include: {
                relation: 'passenger',
                scope: {
                  isDeleted: true,
                },
              },
            },
          },
          viewMode === 'TIMELINE' ? 'events' : null,
        ].filter(v => !!v),
      },
      true,
    );
  }

  getTripSearchResults = memoize((trips, term, fuse) => {
    if (!trips) {
      return [];
    }
    if (!term) {
      return trips;
    }
    fuse = fuse || new Fuse(getTripsWithSearchProperties(trips), FUSE_OPTIONS);
    return fuse.search(term).map(result => result.item);
  });

  openTripDetails = trip => {
    this.setState({
      openTrip: trip,
      tripDetailsOpen: true,
    });
  };

  closeTripDetails = () => {
    this.setState({
      openTrip: null,
      tripDetailsOpen: false,
    });
  };

  openTripNotifications = trip => {
    this.setState({
      openTrip: trip,
      tripNotificationsOpen: true,
    });
  };

  closeTripNotifications = () => {
    this.setState({
      openTrip: null,
      tripNotificationsOpen: false,
    });
  };

  setViewMode = viewMode => {
    this.setState({
      viewMode,
    });
  };

  updateSearch = debounce(search => {
    this.setState({
      search,
      // Fake loader to draw attention to the list
      searching: false,
    });
  }, 500);

  updateMatcher = matcher => {
    const { b2cOnly, timeRange } = this.state;
    saveFilters(FILTER_SCOPE, { matcher, b2cOnly, timeRange });
    this.setState(
      {
        matcher,
      },
      () => {
        this.refreshHeaderContent();
      },
    );
  };

  resetFilters = () => {
    this.setState(
      {
        b2cOnly: false,
      },
      () => this.updateMatcher({}),
    );
    saveFilters(FILTER_SCOPE, {
      matcher: {},
      b2cOnly: false,
      timeRange: this.state.timeRange,
    });
  };

  onEditStart = () => {
    this.setState({
      autoRefresh: false,
    });
  };

  onAfterEdit = () => {
    this.setState({
      autoRefresh: true,
    });
    this.refreshTrips(false);
  };

  refreshHeaderContent() {
    const { onHeaderContent, t } = this.props;
    const { matcher, b2cOnly } = this.state;
    // Purposefully uncontrolled for performance reasons
    // otherwise we have to update header content after every render
    // which will render the whole component tree from the parent component
    onHeaderContent(
      <div style={styles.headerContent}>
        <Input
          fluid
          iconPosition="left"
          placeholder={t('Rechercher')}
          style={{ ...styles.headerInput, ...styles.searchInput }}
          icon="search"
          onChange={(e, { value }) => {
            // Fake loader to draw attention to the list
            this.setState({
              searching: true,
            });
            this.updateSearch(value);
          }}
        />
        <DatesRangeInput
          allowSameEndDate
          style={styles.headerInput}
          value={this.state.dateRangeString}
          onChange={(e, { value }) => {
            this.setState(
              {
                dateRangeString: value,
                dateRange: parseDateRangeString(value),
              },
              () => {
                this.refreshHeaderContent();
              },
            );
          }}
        />
        <TimeRangeInput
          label={t('Période')}
          style={styles.headerInput}
          value={this.state.timeRange}
          onChange={range => {
            saveFilters(FILTER_SCOPE, { matcher, b2cOnly, timeRange: range });
            this.setState(
              {
                timeRange: range,
              },
              () => {
                this.refreshHeaderContent();
              },
            );
          }}
        />
        {this.renderFiltersDropdown()}
      </div>,
    );
  }

  getMatcherLabel() {
    const { trips, groups, t } = this.props;
    const { matcher } = this.state;
    if (isEmpty(matcher) || !trips) {
      return t('Filtrer');
    }
    const matchedKey = keys(matcher)[0];
    if (matchedKey === 'driverId') {
      const driver = trips.find(t => t.driverId === matcher.driverId)?.driver;
      return `${driverLabel(driver)}`;
    }
    if (matchedKey === 'vehicleId') {
      const vehicle = trips.find(
        t => t.vehicleId === matcher.vehicleId,
      )?.vehicle;
      return `${vehicleLabel(vehicle)}`;
    }
    if (matchedKey === 'groups') {
      return matcher.groups
        ?.map(groupId => {
          const group = groups[groupId];
          return group?.name;
        })
        .join(', ');
    }
  }

  getMatcherIcon() {
    const { matcher } = this.state;
    if (isEmpty(matcher)) {
      return 'filter';
    }
    const matchedKey = keys(matcher)[0];
    if (matchedKey === 'driverId') {
      return 'user';
    }
    if (matchedKey === 'vehicleId') {
      return 'bus';
    }
    if (matchedKey === 'groups') {
      return 'group';
    }
  }

  renderFiltersDropdown() {
    const { trips, t } = this.props;
    const { matcher, b2cOnly, timeRange } = this.state;
    let { groups } = this.props;
    let filteredGroups = {};

    if (groups && trips) {
      filteredGroups = {};

      trips.forEach(trip => {
        if (!trip.groups) {
          return;
        }
        trip.groups.forEach(groupFromTrip => {
          for (const [key, value] of Object.entries(groups)) {
            if (groupFromTrip === key && !(key in filteredGroups)) {
              filteredGroups[key] = value;
            }
          }
        });
      });

      if (filteredGroups) {
        groups = filteredGroups;
      }
    }

    const drivers = uniqBy(
      trips?.map(trip => trip.driver).filter(v => v),
      'id',
    );
    const vehicles = uniqBy(
      trips?.map(trip => trip.vehicle).filter(v => v),
      'id',
    );
    return (
      <Menu>
        <Dropdown
          trigger={
            <div>
              <Icon name={this.getMatcherIcon()} />
              <div style={styles.filtersDropdownLabel}>
                {b2cOnly ? `${t('B2C')} - ` : ''}
                {this.getMatcherLabel()}
              </div>
            </div>
          }
          style={styles.filtersDropdown}
          className="icon dashboard-filters-dropdown"
          fluid
          item
        >
          <Dropdown.Menu>
            <Dropdown.Item onClick={this.resetFilters}>
              {t('Annuler filtres')}
            </Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown className="item" fluid text={t('Conduct.')}>
              <Dropdown.Menu>
                {drivers.map(driver => (
                  <Dropdown.Item
                    key={driver.id}
                    onClick={() =>
                      this.updateMatcher({
                        driverId: driver.id,
                      })
                    }
                    active={matcher.driverId === driver.id}
                  >
                    {driverLabel(driver)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="item" fluid text={t('Véhicule')}>
              <Dropdown.Menu>
                {vehicles.map(vehicle => (
                  <Dropdown.Item
                    key={vehicle.id}
                    onClick={() =>
                      this.updateMatcher({
                        vehicleId: vehicle.id,
                      })
                    }
                    active={matcher.vehicleId === vehicle.id}
                  >
                    {vehicleLabel(vehicle)}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown className="item" fluid text={t('Groupe')}>
              <Dropdown.Menu>
                {map(groups, group => (
                  <Dropdown.Item
                    key={group._id}
                    onClick={() =>
                      this.updateMatcher({
                        groups: uniq(
                          (this.state.matcher.groups || []).concat(group._id),
                        ),
                      })
                    }
                    active={matcher.groups?.includes(group._id)}
                  >
                    {group.name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown.Item
              onClick={() => {
                saveFilters(FILTER_SCOPE, {
                  matcher,
                  b2cOnly: true,
                  timeRange,
                });
                this.setState(
                  {
                    b2cOnly: true,
                  },
                  () => {
                    this.refreshHeaderContent();
                  },
                );
              }}
              active={b2cOnly}
            >
              {t('B2C')}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
    );
  }

  render() {
    const { loading, trips, account, t } = this.props;
    const {
      openTrip,
      tripDetailsOpen,
      tripNotificationsOpen,
      viewMode,
      search,
      timeRange,
      matcher,
      searching,
      b2cOnly,
    } = this.state;

    const tripsToDisplay = filterOutHiddenVehiclesDrivers(trips);
    const tripSearchResults = this.getTripSearchResults(
      tripsToDisplay,
      search,
      this.fuse,
    );
    const tripsInRange = filterTripsByTimeRange(tripSearchResults, timeRange);
    const matchingTrips = b2cOnly
      ? filterB2CTrips(filterTripsByMatcher(tripsInRange, matcher))
      : filterTripsByMatcher(tripsInRange, matcher);

    const isSuperAdmin = account?.roles?.includes('super_admin');
    const isLoading = loading || searching;
    return (
      <div style={styles.container} className="dashboard">
        <div>
          {isSuperAdmin && (
            <Button.Group color="violet">
              <Button
                basic={viewMode !== 'MAP'}
                loading={viewMode === 'MAP' && isLoading}
                onClick={() => this.setViewMode('MAP')}
              >
                {(viewMode !== 'MAP' || !isLoading) && (
                  <ReactSVG
                    className={`view-mode-icon ${
                      viewMode === 'MAP' && 'active'
                    }`}
                    src={mapIcon}
                  />
                )}
              </Button>
              <Button
                basic={viewMode !== 'TIMELINE'}
                loading={viewMode === 'TIMELINE' && isLoading}
                onClick={() => this.setViewMode('TIMELINE')}
              >
                {(viewMode !== 'TIMELINE' || !isLoading) && (
                  <ReactSVG
                    className={`view-mode-icon ${
                      viewMode === 'TIMELINE' && 'active'
                    }`}
                    src={tableColumnIcon}
                  />
                )}
              </Button>
            </Button.Group>
          )}
        </div>
        {viewMode === 'MAP' && (
          <div
            style={{
              ...styles.mapView,
              ...(!isSuperAdmin && styles.mapViewOnly),
            }}
          >
            <div style={styles.map}>
              <VehiclesMap
                matcher={matcher}
                onTripDetailsClick={this.openTripDetails}
                trips={matchingTrips}
              />
            </div>
            <div style={styles.logsAndTrips}>
              <UpcomingTripsSummary
                trips={matchingTrips}
                onTripDetailsClick={this.openTripDetails}
                onTripNotificationsClick={this.openTripNotifications}
                search={search}
                loading={searching}
              />
            </div>
          </div>
        )}
        {viewMode === 'TIMELINE' && (
          <div style={styles.timelineView}>
            <TripsTimelineView
              trips={matchingTrips}
              loading={searching}
              onAfterEdit={this.onAfterEdit}
              onEditStart={this.onEditStart}
            />
          </div>
        )}
        <DialogBox
          title={t('Détails du trajet')}
          onClose={this.closeTripDetails}
          open={!!openTrip && tripDetailsOpen}
          style={{ minWidth: 1000, maxHeight: '90%' }}
        >
          {openTrip && <TripDetails trip={openTrip} />}
        </DialogBox>
        <DialogBox
          title={t('Envoyer une notification')}
          onClose={this.closeTripNotifications}
          open={!!openTrip && tripNotificationsOpen}
          style={{ minWidth: 1000, maxHeight: '90%' }}
        >
          {openTrip && (
            <TripNotifications
              trip={openTrip}
              onFinish={this.closeTripNotifications}
            />
          )}
        </DialogBox>
      </div>
    );
  }
}

const styles = {
  container: {
    height: 'calc(100% - 80px)',
    display: 'flex',
    flexDirection: 'column',
  },
  mapView: {
    height: 'calc(100% - 36px)',
    display: 'flex',
  },
  mapViewOnly: {
    height: '100%',
  },
  timelineView: {
    height: 'calc(100% - 36px)',
  },
  map: {
    flex: 3,
    paddingTop: 10,
    paddingBottom: 20,
  },
  logsAndTrips: {
    flex: 1,
    height: '100%',
  },
  headerContent: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headerInput: {
    marginRight: 15,
  },
  searchInput: {
    flex: 1,
    marginLeft: 15,
    maxWidth: 310,
  },
  filtersDropdownLabel: {
    display: 'inline-block',
  },
};

Dashboard.defaultProps = {
  trips: [],
  groups: [],
};

Dashboard.propTypes = {
  trips: PropTypes.arrayOf(tripPropType),
  groups: PropTypes.objectOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
  onHeaderContent: PropTypes.func.isRequired,
};

export default withi18n('dashboard')(withRouter(Dashboard));
