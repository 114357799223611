import React, { Component } from 'react';
import { Modal, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';

export default class DialogBoxStops extends Component {
  render() {
    const { actions, children, openBoxStops, onClose, title } = this.props;
    return (
      <Modal
        onClose={onClose}
        dimmer="inverted"
        open={openBoxStops}
        size="small"
      >
        <Modal.Header>
          <div style={styles.header}>
            <div style={styles.left}>
              <a href="#/" onClick={onClose}>
                <Icon name="arrow left" color="violet" size="small" />
              </a>
              <label style={styles.title}>{title}</label>
            </div>
            <a href="#/" onClick={onClose}>
              <Icon name="close" color="violet" size="small" />
            </a>
          </div>
        </Modal.Header>
        <Modal.Content image>
          <Modal.Description style={{ width: '100%' }}>
            {children}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>{actions}</Modal.Actions>
      </Modal>
    );
  }
}

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  title: { paddingLeft: 10 },
};

DialogBoxStops.defaultProps = {
  actions: null,
  children: null,
  title: '',
};

DialogBoxStops.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  openBoxStops: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
};
