import React, { Component } from 'react';
import {
  Button,
  Card,
  Icon,
  Dropdown,
  Image,
  Form,
  TextArea,
  Rating,
  Radio,
} from 'semantic-ui-react';
import { ReactSVG } from 'react-svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { DateTimeInput } from 'semantic-ui-calendar-react';

import DialogBox from '../../common/DialogBox';
import TripForm from '../../trips/TripForm';
import StopSteps from '../../common/StopSteps';
import DialogBoxStops from '../../common/DialogBoxStops';
import defaultUser from '../../../assets/images/user.png';
import bus from '../../../assets/images/jam-bus.svg';
import colors from '../../../theme/colors';
import Stops from '../stops/Stops';

import { Link } from 'react-router-dom';
import tripPropType from '../tripPropType';

const days = {
  MON: 'lun',
  TUE: 'mar',
  WED: 'mer',
  THU: 'jeu',
  FRI: 'ven',
  SAT: 'sam',
  SUN: 'dim',
};

export default class Trip extends Component {
  constructor(props) {
    super(props);
    const { trip } = this.props;
    this.state = {
      trip,
      seeStops: false,
      open: false,
      display: false,
      openBoxStops: false,
      openNotify: false,
      notificationDate: moment(new Date(), 'DD-MM-YYYY HH:mm'),
      notificationContent: '',
      charsLeft: 200,
      sendNow: true,
      duplicating: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { editing, error, trip, creating } = this.props;
    if (!editing && prevProps.editing) {
      if (!error) {
        this.setState({ open: false, openBoxStops: false });
      }
    }
    if (creating && prevProps.creating === false) {
      if (!prevProps.error) {
        this.setState({ open: false });
      }
    }
    if (prevProps.trip !== trip) {
      this.setState({ trip });
    }
  }

  handleToggle = value => {
    const { trip } = this.state;
    this.setState({ displayRecurrence: value });
    if (!value) {
      this.setState({ trip: { ...trip, recurrence: null } });
    }
  };

  editTrip = () => {
    const { editTrip } = this.props;
    const { trip } = this.state;
    editTrip(omit(trip, ['vehicle', 'driver']));
  };

  duplicateTrip = () => {
    const { createTrip } = this.props;
    const { trip } = this.state;
    createTrip(omit(trip, ['vehicle', 'driver', 'id', 'notifications']));
  };

  onDisplay = () => {
    const { display } = this.state;
    this.setState({
      display: !display,
    });
  };

  notify() {
    this.setState({
      notificationDate: moment(new Date(), 'DD-MM-YYYY HH:mm'),
      charsLeft: 200,
      openNotify: true,
    });
  }

  handleDateChange = (e, { value }) => {
    const notificationDate = moment(value, 'DD-MM-YYYY HH:mm');
    this.setState({ notificationDate });
  };

  handleContentChange = (e, { value }) => {
    this.setState({ notificationContent: value });
    this.setState({ charsLeft: 200 - value.length });
  };

  sendNotification() {
    const { notificationDate, notificationContent, trip, sendNow } = this.state;
    const { sendNotification } = this.props;

    if (!sendNow && moment().isAfter(notificationDate)) {
      alert('Impossible de notifier à une date antérieure.');
      return;
    }

    if (trip.notifications) {
      if (sendNow) {
        sendNotification(trip.topicId, notificationContent);
      } else {
        sendNotification(trip.topicId, notificationContent, notificationDate);
      }
      this.setState({ openNotify: false });
    } else {
      alert('Envoi de notification impossible pour ce voyage');
    }
  }

  toggleSendNow = () => {
    const { sendNow } = this.state;
    this.setState({ sendNow: !sendNow, notificationDate: moment() });
  };

  isTripValid = trip => {
    return (
      trip.driverId &&
      trip.vehicleId &&
      trip.departureTime &&
      trip.from &&
      trip.to
    );
  };

  render() {
    const { withoutNotifyButton, deleteTrip } = this.props;
    const {
      trip,
      openBoxStops,
      openNotify,
      notificationDate,
      charsLeft,
      sendNow,
      open,
      duplicating,
    } = this.state;
    return (
      <Card style={styles.card}>
        <Card.Header style={styles.header}>
          <div style={styles.ride}>
            <div style={styles.headerContainer}>
              <Image
                style={styles.avatar}
                size="tiny"
                circular
                src={defaultUser}
              />
              <div style={styles.driverInfos}>
                <label style={styles.name}>
                  {trip.driver
                    ? `${trip.driver && trip.driver.firstname} ${
                        trip.driver && trip.driver.lastname
                      }`
                    : 'Pas de conducteur'}
                </label>
                <label style={styles.service}>
                  {trip.vehicle && trip.vehicle.carNumber}
                </label>
              </div>
            </div>

            <Dropdown icon="ellipsis vertical">
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() =>
                    this.setState({ duplicating: false, open: true })
                  }
                  text="Modifier"
                />
                <Dropdown.Item
                  onClick={() =>
                    this.setState({ open: true, duplicating: true })
                  }
                  text="Dupliquer"
                />
                <Dropdown.Item
                  onClick={() => {
                    window.confirm(
                      'Voulez-vous vraiment supprimer ce voyage ?',
                    ) && deleteTrip(trip.id);
                  }}
                  text="Supprimer"
                />
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div style={styles.rating}>
            {withoutNotifyButton && (
              <Rating icon="star" defaultRating={3} maxRating={5} />
            )}
          </div>
          {!withoutNotifyButton && (
            <Button style={styles.notifyButton} onClick={() => this.notify()}>
              <Icon name="alarm" />
              Notifier
            </Button>
          )}
        </Card.Header>
        <Card.Content>
          <div style={styles.rideBus}>
            <div style={styles.busPlace}>
              <ReactSVG src={bus} />
              <label style={styles.seats}>{`${
                trip.vehicle && trip.vehicle.capacity
              } places`}</label>
            </div>
            <div style={styles.ridePeriodInfo}>
              <label>
                {trip.recurrence
                  ? `Chaque ${trip.recurrence.days.map(
                      d => days[d],
                    )} à ${moment(trip.departureTime).format('HH:mm')}`
                  : `Départ ${moment(trip.departureTime).format(
                      'DD.MM.YY',
                    )} à ${moment(trip.departureTime).format('HH:mm')}`}
              </label>
            </div>
          </div>
          <StopSteps
            onClick={this.onDisplay.bind(this)}
            from={trip.from?.name}
            to={trip.to?.name}
            stops={trip.stops}
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <div style={styles.stops}>
                <button
                  style={styles.displayStops}
                  onClick={() => this.setState({ openBoxStops: true })}
                >
                  Voir les arrêts
                </button>
              </div>
            </div>
            <div>
              <Link
                to={`/pro/Notifications/${trip.topicId}`}
                style={styles.displayStops}
              >
                {' '}
                Notifications
              </Link>
            </div>
          </div>
        </Card.Content>
        <DialogBoxStops
          onClose={() => this.setState({ openBoxStops: false })}
          title="Ajouter / Modifier Arrêt(s)"
          actions={
            <Button onClick={this.editTrip} primary>
              Enregistrer
            </Button>
          }
          openBoxStops={openBoxStops}
        >
          <Stops trip={trip} onChange={trip => this.setState({ trip })} />
        </DialogBoxStops>
        <DialogBox
          onClose={() => this.setState({ open: false })}
          title={duplicating ? 'Dupliquer un voyage' : 'Modifier un voyage'}
          actions={
            <Button
              disabled={!this.isTripValid(trip)}
              onClick={duplicating ? this.duplicateTrip : this.editTrip}
              primary
            >
              {duplicating ? 'Dupliquer' : 'Modifier'}
            </Button>
          }
          open={open}
        >
          <TripForm trip={trip} onChange={trip => this.setState({ trip })} />
        </DialogBox>
        <DialogBox
          onClose={() => this.setState({ openNotify: false })}
          title="Envoyer notification"
          actions={
            <Button onClick={() => this.sendNotification()} primary>
              Envoyer
            </Button>
          }
          open={openNotify}
        >
          <Form>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                paddingBottom: 10,
              }}
            >
              <label>Envoyer maintenant</label>
              <Radio toggle checked={sendNow} onChange={this.toggleSendNow} />
            </div>

            <div
              style={{
                paddingBottom: 10,
              }}
            >
              <label>Date et heure de la notification</label>
              <DateTimeInput
                disabled={sendNow}
                closable
                onChange={this.handleDateChange}
                value={notificationDate.format('DD-MM-YYYY HH:mm')}
                minDate={moment()}
              />
            </div>

            <TextArea
              onChange={this.handleContentChange}
              placeholder="Dites ce que vous voulez notifier"
              maxLength="200"
            />
            <div>{charsLeft + '/200'}</div>
          </Form>
        </DialogBox>
      </Card>
    );
  }
}

const styles = {
  avatar: { width: 50, height: 50 },
  firstRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 5,
  },
  stops: {
    display: 'flex',
    justifyContent: 'center',
  },
  displayStops: {
    backgroundColor: colors.BLUE,
    cursor: 'pointer',
    color: 'white',
    borderRadius: 30,
    padding: 10,
  },
  headerText: {
    fontWeight: 'bold',
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  driverInfos: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 5,
  },
  name: {
    fontWize: 16,
    fontWeight: 'bold',
    color: colors.DARK_BLUE,
  },
  notifyButton: {
    position: 'absolute',
    right: 10,
    bottom: -12,
    borderRadius: 30,
    backgroundColor: colors.APRICOT,
    fontSize: 10,
    boxShadow:
      '0 8px 10px -4px rgba(130, 136, 148, 0.28), 0 0 0 1px rgba(130, 136, 148, 0.16)',
    color: colors.WHITE,
  },
  busPlace: {
    display: 'flex',
    flexDirection: 'row',
  },
  card: {
    width: 269,
    borderRadius: 10,
    boxShadow: '0 3px 8px -4px',
    marginRight: 3,
  },
  header: {
    backgroundColor: colors.HEADER_LIGHT_BACKGROUND,
    position: 'relative',
  },
  seats: {
    fontWeight: 'bold',
    paddingLeft: 7,
  },
  service: {
    color: colors.TITLE_LABEL,
    fontSize: 12,
  },
  ride: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 5,
    paddingRight: 5,
  },
  rideBus: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    paddingBottom: 10,
  },
  ridePeriodInfo: {
    color: colors.GREY,
    fontSize: 10,
  },
  rating: {
    display: 'flex',
    paddingBottom: 35,
    paddingTop: 10,
    position: 'absolute',
    right: 10,
    bottom: -12,
    borderRadius: 30,
  },
};

Trip.propTypes = {
  trip: tripPropType,
  withoutNotifyButton: PropTypes.bool.isRequired,
  deleting: PropTypes.bool.isRequired,
  editing: PropTypes.bool.isRequired,
  deleteTrip: PropTypes.func.isRequired,
  editTrip: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  notificationError: PropTypes.string,
};
