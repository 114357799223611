import { handleActions } from 'redux-actions';

import notificationActions from '../../actions/notification';

const DEFAULT_STATE = {
  loading: false,
  error: null,
  notificationsList: null,
};

export default handleActions(
  {
    [notificationActions.sendNotification]: state => ({
      ...state,
      loading: true,
      error: null,
    }),
    [notificationActions.sendNotificationError]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [notificationActions.sendNotificationSuccess]: state => ({
      ...state,
      loading: false,
      error: null,
    }),
    [notificationActions.requestNotificationsList]: state => ({
      ...state,
      loading: true,
      error: null,
    }),
    [notificationActions.requestNotificationsListError]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload,
    }),
    [notificationActions.updateNotificationsList]: (state, action) => ({
      ...state,
      notificationsList: action.payload,
    }),
  },
  DEFAULT_STATE,
);
