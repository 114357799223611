import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import get from 'lodash/get';
import memoize from 'memoize-one';

import CRUDManager from '../common/CRUDManager';
import driversActions from '../../actions/drivers';
import groupsActions from '../../actions/groups';

const fields = [
  {
    key: 'registrationNumber',
    label: 'Matricule',
    type: 'STRING',
  },
  {
    key: 'firstname',
    label: 'Prénom',
    type: 'STRING',
  },
  {
    key: 'lastname',
    label: 'Nom',
    type: 'STRING',
  },
  {
    key: 'phone',
    label: 'Téléphone',
    type: 'PHONE',
  },
  {
    key: 'available',
    label: 'Disponibilité',
    type: 'BOOLEAN',
    fontSize: 15,
    suffix: 'disponible',
  },
  {
    key: 'unavailabilityReason',
    label: 'Raison',
    type: 'STRING',
    list: ['Maladie', 'Congé'],
    disabled: record => get(record, 'available', false),
  },
  {
    key: 'groups',
    label: 'Groupes',
    type: ['REF'],
    ref: 'groups',
    refKey: 'name',
  },
  {
    key: 'transporterCompany',
    label: 'Transporteur',
    type: 'STRING',
  },
];
const resourceLabel = 'conducteur';

const getRelations = memoize(groups => ({ groups }));
const translateError = memoize(error => {
  if (!error) {
    return null;
  }
  if (error.code === 422) {
    return new Error('Ce matricule existe déjà');
  }
  return error;
});

const style = { height: 'calc(100% - 80px)' };

const mapStateToProps = state => ({
  recordKey: 'id',
  records: state.drivers,
  relations: getRelations(state.groups),
  resourceLabel,
  fields,
  style,
  ...state.driversMeta,
  error: translateError(state.driversMeta.error),
  canImport: true,
});

const mapDispatchToProps = dispatch => ({
  fetchStart(...args) {
    dispatch(driversActions.fetchStart(...args));
    dispatch(groupsActions.fetchStart());
  },
  createStart: compose(dispatch, driversActions.createStart),
  updateStart: compose(dispatch, driversActions.updateStart),
  deleteStart: compose(dispatch, driversActions.deleteStart),
});

export default connect(mapStateToProps, mapDispatchToProps)(CRUDManager);
