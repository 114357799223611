import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import moment from 'moment';

import colors from '../../theme/colors';

export default class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      notification: {
        content: '',
        date: '',
        sent: false,
      },
    };
  }

  componentDidMount() {
    const { requestNotificationsList, topicId } = this.props;
    requestNotificationsList(topicId);
  }

  renderCell(n) {
    const status = moment().isBefore(n.dateNotification)
      ? 'En cours pour le\n' +
        moment(n.dateNotification).format('d MMMM YYYY à HH:mm')
      : 'Envoyée le\n' +
        moment(n.dateNotification).format('d MMMM YYYY à HH:mm');
    let type = 'N/A';
    switch (n.type) {
      case 'trip::message::admin':
        type = 'Administrateur';
        break;
      case 'Notification::Trip:Start':
        type = 'Début du voyage';
        break;
      default:
        break;
    }
    return (
      <Table.Row key={n._id}>
        <Table.Cell>
          <label> {type}</label>
        </Table.Cell>
        <Table.Cell>{n.content} </Table.Cell>
        <Table.Cell>
          {`Le ${moment(n.created_at).format('d MMMM YYYY à HH:mm')}`}
        </Table.Cell>
        <Table.Cell>
          <label>{status}</label>
        </Table.Cell>
      </Table.Row>
    );
  }

  render() {
    const { notificationsList } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.lineAdd}>
          <label style={styles.vehiclesLabel}>NOTIFICATIONS</label>
        </div>

        <Table basic="very">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell style={styles.headerText}>
                TYPE
              </Table.HeaderCell>
              <Table.HeaderCell style={styles.headerText}>
                CONTENU DE LA NOTIFICATION
              </Table.HeaderCell>
              <Table.HeaderCell style={styles.headerText}>
                DATE DE CRÉATION
              </Table.HeaderCell>
              <Table.HeaderCell style={styles.headerText}>
                STATUT
              </Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          {notificationsList && notificationsList.map(n => this.renderCell(n))}
        </Table>
      </div>
    );
  }
}

const styles = {
  lineAdd: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: 5,
  },
  actionButtons: { display: 'flex', justifyContent: 'space-around' },
  statusContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  container: { height: '100%', backgroundColor: colors.WHITE, padding: 20 },
  headerText: { color: colors.GREY },
  vehiclesLabel: { fontWeight: 'bold' },
};

Notifications.propTypes = {
  notificationsList: PropTypes.arrayOf(
    PropTypes.shape({
      notificatino: PropTypes.shape({
        id: PropTypes.string,
        type: PropTypes.string,
        content: PropTypes.string,
        created_at: PropTypes.string,
      }),
    }),
  ).isRequired,
  requestNotificationsList: PropTypes.func.isRequired,
};
