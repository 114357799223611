import React, { Component } from 'react';
import colors from '../../theme/colors';

export default class StopSteps extends Component {
  
  render() {
    const { from, to,  } = this.props;
    return (
      <div style={styles.container}>
        <div style={styles.steps}>
          <div style={styles.circleBlue} />
          <div style={styles.separatorBlue} />
          <div style={styles.point} />
          <div style={styles.separatorGray} />
          <div style={styles.circleGray} />
        </div>
        <div style={styles.labels}>
          <label style={{ fontSize: 12 }}>
            {from}
          </label>
          <label style={{ fontSize: 12 }}>{to}</label>
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
  steps: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 2,
    paddingBottom: 2,
  },
  labels: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingLeft: 5,
  },
  circleBlue: {
    width: 10,
    height: 10,
    borderRadius: 5,
    border: '2px solid rgba(255, 255, 255, 0.5)',
    backgroundColor: colors.BLUE_STEPS,
  },
  circleGray: {
    width: 10,
    height: 10,
    borderRadius: 5,
    border: '2px solid rgba(255, 255, 255, 0.5)',
    backgroundColor: colors.GREY,
  },
  separatorBlue: {
    width: 0,
    height: 8,
    border: '1px solid #3a84ff',
  },
  separatorGray: {
    width: 0,
    height: 8,
    border: '1px solid rgba(148, 151, 155, 0.32)',
  },
  point: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: colors.BLUE_STEPS,
  },
};
