import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Card, Divider, Input, Icon } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import memoize from 'memoize-one';

import Trips from '../../../components/trips/Trips';
import DialogBox from '../../../components/common/DialogBox';
import TripForm from '../../../components/trips/TripForm';
import DriverRotationTool from '../../../components/trips/DriverRotationTool';
import colors from '../../../theme/colors';

class TripsPlanningScreen extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      rotationModalOpen: false,
      trip: {},
      datesRange: '',
    };
  }

  componentDidMount() {
    const {
      clearTrips,
      requestTrips,
      requestDrivers,
      requestVehicles,
    } = this.props;
    clearTrips();
    requestTrips();
    requestDrivers();
    requestVehicles();
  }

  componentWillReceiveProps(nextProps) {
    const { creating } = this.props;
    if (creating && nextProps.creating === false) {
      if (!nextProps.error) {
        this.setState({ open: false });
      }
    }
  }

  ongoingTrips = memoize(trips => {
    return (
      trips &&
      trips.filter(p => {
        if (!p.endDate) {
          return moment().diff(moment(p.departureTime)) <= 0;
        } else return moment().diff(moment(p.endDate)) <= 0;
      })
    );
  });

  finishedTrips = memoize(trips => {
    return (
      trips &&
      trips.filter(p => {
        if (!p.endDate) {
          return moment().diff(moment(p.departureTime)) > 0;
        } else return moment().diff(moment(p.endDate)) > 0;
      })
    );
  });

  filterTripsByPeriod = (e, el) => {
    const { requestTripsInPeriod } = this.props;
    this.setState({ datesRange: el.value });
    const range = el.value;
    const dates = range.replace(/\s/g, '').split('-');
    if (dates.length === 2 && !!dates[1]) {
      const start = moment(dates[0], 'DD/MM/YY').format('YYYY-MM-DD');
      const end = moment(dates[1], 'DD/MM/YY').format('YYYY-MM-DD');
      requestTripsInPeriod({ start, end });
    }
  };

  createTrip() {
    const { createTrip } = this.props;
    const { trip } = this.state;
    createTrip(trip);
  }

  updateTrips = trips => {
    const { editTrip } = this.props;
    trips.forEach((trip, index) => {
      const silent = index !== trips.length - 1;
      editTrip(trip, silent);
    });
  };

  openDriverRotationModal = () => {
    this.setState({
      rotationModalOpen: true,
    });
  };

  closeDriverRotationModal = () => {
    this.setState({
      rotationModalOpen: false,
    });
  };

  isTripValid = trip => {
    return (
      trip.driverId &&
      trip.vehicleId &&
      trip.departureTime &&
      trip.from &&
      trip.to
    );
  };

  render() {
    const { trips, drivers, vehicles, requestTrips } = this.props;
    const { datesRange, open, trip, rotationModalOpen } = this.state;
    return (
      <Card fluid style={styles.container}>
        <div style={styles.firstRow}>
          <label style={styles.headerText}>PLANNING DE TRANSPORT OCP</label>
          <div>
            <Button
              disabled={!(drivers && vehicles)}
              primary
              onClick={this.openDriverRotationModal}
            >
              Rotation Automatique
            </Button>
            <Button
              disabled={!(drivers && vehicles)}
              primary
              onClick={() => this.setState({ open: true })}
            >
              Nouveau Voyage
            </Button>
          </div>
        </div>
        <Divider />
        <div style={styles.actions}>
          <DatesRangeInput
            clearable
            clearIcon={<Icon name="remove" color="red" />}
            onClear={() => {
              requestTrips();
              this.setState({ datesRange: '' });
            }}
            dateFormat="DD/MM/YY"
            localization="fr"
            closable
            allowSameEndDate
            placeholder="Filtrer De - à"
            value={datesRange}
            iconPosition="left"
            onChange={this.filterTripsByPeriod}
            style={{ width: 190 }}
          />
          <Input
            style={{ width: 340, borderRadius: 5 }}
            placeholder="Rechercher..."
            icon="search"
            iconPosition="left"
            onChange={() => {}}
          />
        </div>
        <Divider />
        <div style={styles.column}>
          <label style={styles.rideRunning}>Voyage en cours</label>
          <Trips trips={this.ongoingTrips(trips)} />
        </div>
        <div style={styles.column}>
          <label style={styles.rideRunning}>Voyages Terminés</label>
          <Trips trips={this.finishedTrips(trips)} withoutNotifyButton />
        </div>
        <DialogBox
          onClose={() => this.setState({ open: false })}
          title="Ajouter un voyage"
          actions={
            <Button
              disabled={!this.isTripValid(trip)}
              onClick={() => this.createTrip()}
              primary
            >
              Ajouter
            </Button>
          }
          open={open}
        >
          <TripForm trip={trip} onChange={trip => this.setState({ trip })} />
        </DialogBox>
        <DialogBox
          onClose={this.closeDriverRotationModal}
          title="Rotation de conducteurs"
          open={rotationModalOpen}
        >
          <DriverRotationTool
            trips={this.ongoingTrips(trips)}
            onSave={trips => this.updateTrips(trips)}
          />
        </DialogBox>
      </Card>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: colors.WHITE,
    margin: 5,
    padding: 20,
  },
  firstRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 5,
  },
  headerText: {
    fontWeight: 'bold',
  },
  lineSeparator: {
    height: 1,
    backgroundColor: colors.LIGHT_GREY,
    border: '1px solid #dddddd',
  },
  rideRunning: {
    fontWeight: 'bold',
    margin: '20px 0',
    color: colors.TITLE_LABEL,
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  column: { display: 'flex', flexDirection: 'column' },
};

TripsPlanningScreen.propTypes = {
  requestDrivers: PropTypes.func.isRequired,
  requestTrips: PropTypes.func.isRequired,
  requestVehicles: PropTypes.func.isRequired,
  requestTripsInPeriod: PropTypes.func.isRequired,
  createTrip: PropTypes.func.isRequired,
  creating: PropTypes.bool.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default withRouter(TripsPlanningScreen);
