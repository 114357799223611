import { take, all, put, delay, spawn, takeLatest } from 'redux-saga/effects';
import moment from 'moment';
import mapValues from 'lodash/mapValues';
import last from 'lodash/last';
import toArray from 'lodash/toArray';
import uniqBy from 'lodash/uniqBy';
import reverse from 'lodash/reverse';
import maxBy from 'lodash/maxBy';
import jwt from 'jsonwebtoken';

import client from '../../providers/weego-client';
import liveMapActions from '../../actions/liveMap';

const tokens = JSON.parse(localStorage.getItem('tokens'));

const accountVerificationToken = tokens && tokens.ACCOUNT_VERIFICATION;
const decodedToken =
  accountVerificationToken && jwt.decode(accountVerificationToken.token);

function* updateLiveMapPositions({ payload: { sinceDate } }) {
  try {
    if (!decodedToken || !decodedToken.company) {
      return;
    }
    const positions = yield client.tracking.getPositions(
      decodedToken.company.name,
      undefined,
      {
        startDate: sinceDate,
      },
    );
    yield put(
      liveMapActions.updatePositions(
        mapValues(positions, positionGroup => last(positionGroup)),
      ),
    );
  } catch (e) {
    console.error(e);
  }
}

function* periodicallyUpdatePositions() {
  yield put(liveMapActions.requestUpdate(moment().subtract(1, 'day').toDate()));
  yield take(liveMapActions.updatePositions);
  while (true) {
    yield delay(5000);
    // We get positions from 5 minutes before to make sure
    // we don't miss any update because some GPS take time
    // to send their data to the server
    yield put(
      liveMapActions.requestUpdate(moment().subtract(5, 'minutes').toDate()),
    );
  }
}

function* fetchVehicleHistory(action) {
  const { number, startDate, endDate } = action.payload;
  const positionsByTrackerId = yield client.tracking.getPositions(
    undefined,
    number,
    {
      startDate,
      endDate,
    },
  );
  // For a single vehicle, we can have multiple trackers
  // (e.g. gps + phone), we choose the best source
  // by taking the one that has the most valid positions
  const bestPositions = maxBy(
    toArray(positionsByTrackerId).map(positions =>
      uniqBy(
        positions,
        pos => `${pos.coordinates.latitude},${pos.coordinates.longitude}`,
      ).filter(p => p.coordinates.latitude && p.coordinates.longitude),
    ),
    'length',
  );
  const ascPositions = reverse(bestPositions);
  yield put(liveMapActions.updateVehicleHistory(ascPositions));
}

function* liveMapRootSaga() {
  yield all([
    takeLatest(liveMapActions.requestUpdate.toString(), updateLiveMapPositions),
    takeLatest(
      liveMapActions.requestVehicleHistory.toString(),
      fetchVehicleHistory,
    ),
    spawn(periodicallyUpdatePositions),
  ]);
}

export default liveMapRootSaga;
