import { connect } from 'react-redux';
import compose from 'recompose/compose';
import toArray from 'lodash/toArray';

import TripForm from './TripForm';

import groupsActions from '../../../actions/groups';

const mapStateToProps = state => ({
  drivers: toArray(state.drivers),
  vehicles: toArray(state.vehicles),
  groups: state.groups,
});

const mapDispatchToGroups = dispatch => ({
  fetchGroups: compose(dispatch, groupsActions.fetchStart),
});

const enhance = compose(connect(mapStateToProps, mapDispatchToGroups));

const DriversContainer = enhance(TripForm);

export default DriversContainer;
