import React from 'react';
import { compose, withProps } from 'recompose';
import { withGoogleMap, GoogleMap } from 'react-google-maps';
import mapStyles from './mapStyles.json'

const styles = {
  fullHeight: {
    height: '100%',
  },
};

const Map = compose(
  withProps({
    loadingElement: <div style={styles.fullHeight} />,
    containerElement: <div style={styles.fullHeight} />,
    mapElement: <div style={styles.fullHeight} />,
  }),
  withGoogleMap,
)(({ forwardedRef, ...props }) => (
  <GoogleMap
    options={{
      styles: mapStyles,
    }}
    defaultZoom={props.defaultZoom || 7}
    defaultCenter={props.center || { lat: 33.596387, lng: -7.530349 }}
    ref={forwardedRef}
    {...props}
  />
));

const MapForwardRef = React.forwardRef((props, ref) => (
  <Map {...props} forwardedRef={ref} />
));

export default MapForwardRef;
