import React, { useEffect, useMemo, useRef, useState } from 'react';
import { connect } from 'react-redux';
import compose from 'lodash/flowRight';
import { lifecycle } from 'recompose';
import merge from 'lodash/merge';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import omit from 'lodash/omit';
import memoize from 'memoize-one';
import Promise from 'bluebird';
import {
  Button,
  Confirm,
  Form,
  Header,
  Icon,
  Message,
  TextArea,
} from 'semantic-ui-react';
import ObjectID from 'bson-objectid';

import withi18n from 'weego-common/src/hoc/i18n';
import tryJsonParse from 'weego-common/src/utils/tryJsonParse';
import momentTz from 'weego-common/src/providers/moment';

import client from '../../providers/weego-client';

import CRUDManager from '../common/CRUDManager';
import transactionsActions from '../../actions/transactions';
import accountsActions from '../../actions/accounts';
import accountLabel from '../../utils/accountLabel';
import DialogBox from '../common/DialogBox';
import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import dateToString from '../../utils/date/dateToString';
import vehicleLabel from '../../utils/drivers/vehicleLabel';
import driverLabel from '../../utils/driverLabel';
import stopLabel from '../../utils/stopLabel';

const renderPaymentAccount = (paymentAccount, userAccount) => {
  if (!paymentAccount) {
    return null;
  }
  if (paymentAccount.userId === 'MASTER') {
    return 'Weego';
  }
  if (userAccount) {
    return accountLabel(userAccount, { displayPhoneNumber: true }) || 'N/A';
  }
  return paymentAccount.userId;
};

const fields = [
  {
    key: 'id',
    label: 'Identifiant',
    type: 'STRING',
  },
  {
    key: 'from.userId',
    label: 'Origine',
    refId: '_id',
    type: 'REF',
    ref: 'accounts',
    renderRef: (account, { record } = {}) => {
      return renderPaymentAccount(record?.from, account);
    },
    disableFilter: true,
  },
  {
    key: 'to.userId',
    label: 'Destination',
    refId: '_id',
    type: 'REF',
    ref: 'accounts',
    renderRef: (account, { record } = {}) => {
      return renderPaymentAccount(record?.to, account);
    },
    disableFilter: true,
  },
  {
    key: 'amount',
    label: 'Montant',
    type: 'NUMBER',
    render: ({ record: transaction }) => {
      return transaction.subscriptionId ? 'Abonnement' : transaction.amount;
    },
  },
  {
    key: 'type',
    label: 'Type',
    type: 'ENUM',
    options: [
      {
        value: 'TICKETING',
        label: 'Billeterie',
      },
      {
        value: 'REFILL',
        label: 'Recharge manuelle',
      },
      {
        value: 'REFILL_CMI',
        label: 'Recharge CMI',
      },
      {
        value: 'REFILL_CASHPLUS',
        label: 'Recharge CashPlus',
      },
      {
        value: 'REFUND',
        label: 'Remboursement',
      },
      {
        value: 'INVITE_USER_BONUS',
        label: 'Bonus invitation',
      },
      {
        value: 'FIRST_DEMAND_BONUS',
        label: 'Bonus premier trajet',
      },
      {
        value: 'VOUCHER_REDEEM',
        label: 'Code Promo',
      },
      {
        value: 'VOUCHER_REDEEM_ROLLBACK',
        label: 'Remboursement Code Promo',
      },
      {
        value: 'SUBSCRIPTION',
        label: 'Abonnement',
      },
      {
        value: 'REFILL_PREPAID_CARD',
        label: 'Recharge carte prépayée',
      },
      {
        value: 'THIRD_PARTY_SUBSCRIPTION',
        label: 'Renouvellement carte abonnement',
      },
    ],
  },
  {
    key: 'status',
    label: 'Statut',
    type: 'ENUM',
    options: [
      {
        value: 'SETTLED',
        label: 'Réglé',
      },
      {
        value: 'PENDING',
        label: 'En attente',
      },
      {
        value: 'ERRORRED',
        label: 'Erreur',
      },
      {
        value: 'REFUNDED',
        label: 'Remboursé',
      },
    ],
  },
  {
    key: 'createdAt',
    label: 'Date',
    type: 'DATETIME',
    render({ record }) {
      if (record.type === 'TICKETING') {
        const metadata = tryJsonParse(record.metadata);
        if (metadata?.departureTime) {
          return dateToString(metadata.departureTime);
        }
      }
    },
  },
];
const resourceLabel = 'transaction';

const getRelations = memoize(accounts => ({ accounts }));

const style = { height: 'calc(100% - 80px)' };

const mapStateToProps = state => ({
  recordKey: 'id',
  records: state.transactions,
  relations: getRelations(state.accounts),
  resourceLabel,
  fields,
  style,
  ...state.transactionsMeta,
  error: state.transactionsMeta.error,
  canEdit: false,
  canRemove: false,
  canExport: true,
  canAdd: false,
});

const mapDispatchToProps = dispatch => ({
  fetchStart(payload) {
    if (payload.search) {
      dispatch(
        accountsActions.fetchStart({
          search: payload.search,
          callback: searchResultAccounts => {
            const accountIds = searchResultAccounts.map(account => account._id);
            dispatch(
              transactionsActions.fetchStart(
                merge({}, omit(payload, 'search'), {
                  where: {
                    status: payload.where?.status || {
                      inq: ['SETTLED', 'ERRORED', 'REFUNDED'],
                    },
                    or: [
                      {
                        '$from.userId$': {
                          inq: accountIds,
                        },
                      },
                      {
                        '$to.userId$': {
                          inq: accountIds,
                        },
                      },
                    ],
                  },
                  query: {
                    filter: {
                      include: ['from', 'to'],
                    },
                  },
                }),
              ),
            );
          },
        }),
      );
    } else {
      dispatch(
        transactionsActions.fetchStart(
          merge({}, payload, {
            where: {
              status: payload.where?.status || {
                inq: ['SETTLED', 'ERRORED', 'REFUNDED'],
              },
            },
            query: {
              filter: {
                include: ['from', 'to'],
              },
            },
          }),
        ),
      );
    }
  },
  createStart: compose(dispatch, transactionsActions.createStart),
  updateStart: compose(dispatch, transactionsActions.updateStart),
  updateSuccess: compose(dispatch, transactionsActions.updateSuccess),
  deleteStart: compose(dispatch, transactionsActions.deleteStart),
  fetchAccounts: compose(dispatch, accountsActions.fetchStart),
});

const enhance = compose(
  withi18n('transactions'),
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    componentDidUpdate(prevProps) {
      if (this.props.records !== prevProps.records) {
        const transactions = this.props.records;
        const userIds = uniq(
          flatten(map(transactions, t => [t.from?.userId, t.to?.userId]))
            .filter(v => !!v)
            .filter(v => ObjectID.isValid(v)),
        );
        this.props.fetchAccounts({
          where: {
            _id: {
              inq: userIds,
            },
          },
        });
      }
    },
  }),
);

const TransactionsManager = ({
  records,
  fetchStart,
  createStart,
  updateSuccess,
  creating,
  createError,
  t,
  fields,
  ...props
}) => {
  const [refundModalOpen, setRefundModalOpen] = useState(false);
  const [transactionToRefund, setTransactionToRefund] = useState(null);
  const [refundConfirmOpen, setRefundConfirmOpen] = useState(false);
  const [refundMotive, setRefundMotive] = useState('');
  const [currentOrder, setCurrentOrder] = useState(null);

  const [ticketingTrips, setTicketingTrips] = useState([]);
  const fieldsWithMetadataField = useMemo(() => {
    return fields.concat({
      key: 'metadata',
      label: 'Extra',
      type: 'STRING',
      render({ record: transaction, serialize }) {
        if (transaction.type === 'TICKETING') {
          const metadata = tryJsonParse(transaction.metadata);
          if (!metadata) {
            return null;
          }
          if (metadata.busLine) {
            return (
              metadata.busLine.label ||
              `${metadata.busLine.company} ${metadata.busLine.number}`.trim()
            );
          }
          const trip = ticketingTrips.find(
            t =>
              t.id === metadata.tripId &&
              t.departureTime === metadata.departureTime,
          );
          if (!trip) {
            return null;
          }
          const demandId = metadata.demandId;
          const allStops = [trip.from, ...(trip.stops || []), trip.to];
          const pickupStop = allStops.find(s =>
            s.demandIds?.includes(demandId),
          );
          const dropoffStop = reverse(allStops).find(s =>
            s.demandIds?.includes(demandId),
          );
          const { driver, vehicle } = trip;
          const stopInfos =
            pickupStop && dropoffStop
              ? `${stopLabel(pickupStop)}@${momentTz(pickupStop.maxDate).format(
                  'HH:mm',
                )}\n${stopLabel(dropoffStop)}@${momentTz(
                  dropoffStop.maxDate,
                ).format('HH:mm')}`
              : '';
          const tripInfos = `${vehicleLabel(vehicle)} - ${driverLabel(driver)}`;
          if (serialize) {
            return `${stopInfos}\n${tripInfos}`;
          }
          return (
            <span style={{ whiteSpace: 'pre' }}>
              {`${stopInfos}\n${tripInfos}`}
            </span>
          );
        } else if (
          transaction.type === 'VOUCHER_REDEEM' ||
          transaction.type === 'VOUCHER_REDEEM_ROLLBACK'
        ) {
          const metadata = tryJsonParse(transaction.metadata);
          return `Code: ${metadata?.voucherCode}`;
        } else if (transaction.type === 'REFUND') {
          const metadata = tryJsonParse(transaction.metadata);
          return `Raison: ${metadata?.motive} / ${metadata.targetTransactionId}`;
        } else if (
          transaction.type === 'THIRD_PARTY_SUBSCRIPTION' ||
          transaction.type === 'REFILL_PREPAID_CARD'
        ) {
          const metadata = tryJsonParse(transaction.metadata);
          return `Carte n°: ${metadata?.identifier}`;
        }
        return null;
      },
    });
  }, [fields, ticketingTrips]);

  const wasCreating = useRef(creating);
  // Transactions records need to have a custom sort because of redux-crud default store sorting
  const localSortedRecords = useMemo(
    () =>
      currentOrder
        ? currentOrder.includes('DESC')
          ? reverse(sortBy(records, currentOrder.split(' ')[0]))
          : sortBy(records, currentOrder.split(' ')[0])
        : records,
    [currentOrder, records],
  );

  const openRefundModal = transaction => {
    setRefundModalOpen(true);
    setTransactionToRefund(transaction);
  };

  const closeRefundModal = () => {
    setRefundModalOpen(false);
    setRefundConfirmOpen(false);
    setTransactionToRefund(null);
  };

  const confirmRefund = () => {
    setRefundConfirmOpen(true);
  };

  const submitRefund = () => {
    createStart(
      {
        id: 'STUB',
        type: 'REFUND',
        amount: transactionToRefund.amount,
        from: transactionToRefund.to,
        to: transactionToRefund.from,
        fromId: transactionToRefund.toId,
        toId: transactionToRefund.fromId,
        createdAt: new Date(),
        status: 'SETTLED',
        metadata: {
          motive: refundMotive,
          targetTransactionId: transactionToRefund.id,
        },
      },
      {
        callback: () => {
          updateSuccess({ ...transactionToRefund, status: 'REFUNDED' });
          setRefundMotive('');
        },
      },
    );
  };

  const cancelRefund = () => {
    setRefundConfirmOpen(false);
  };

  useEffect(() => {
    async function fetchTicketingTrips() {
      const ticketingMetadatas = map(records, t => {
        if (t.type !== 'TICKETING') {
          return null;
        }
        const metadata = tryJsonParse(t.metadata);
        if (!metadata) {
          return null;
        }
        return metadata;
      }).filter(v => !!v);
      const trips = await Promise.map(ticketingMetadatas, async metadata => {
        const data = await client.rideBooking.getTripsInPeriod(
          {
            start: momentTz(metadata.departureTime).startOf('day').toDate(),
            end: momentTz(metadata.departureTime).endOf('day').toDate(),
          },
          {
            where: {
              id: metadata.tripId,
            },
            include: ['vehicle', 'driver'],
            isDeleted: true,
          },
          true,
        );
        return data.splitTrips[0];
      });
      setTicketingTrips(trips.filter(v => !!v));
    }
    fetchTicketingTrips();
  }, [records]);

  useEffect(() => {
    if (wasCreating && !creating && !createError) {
      closeRefundModal();
    }
  }, [wasCreating, creating, createError]);

  useEffect(() => {
    wasCreating.current = creating;
  }, [creating]);

  return (
    <div style={style}>
      <DialogBox
        title={t(`Rembourser`)}
        size="tiny"
        onClose={() => closeRefundModal()}
        open={refundModalOpen}
        actions={
          <Button
            primary
            disabled={!refundMotive}
            onClick={() => confirmRefund()}
          >
            {t('Rembourser')}
          </Button>
        }
      >
        {createError && (
          <Message negative>
            <Message.Header>{t('Erreur lors du remboursement')}</Message.Header>
            <p>
              {createError.message || createError.description || createError}
            </p>
          </Message>
        )}
        {transactionToRefund && (
          <Header textAlign="center">
            {t('Remboursement')}:{' '}
            {renderPaymentAccount(
              transactionToRefund.to,
              props.relations.accounts[transactionToRefund.to?.userId],
            )}{' '}
            <Icon name="arrow right" />
            {renderPaymentAccount(
              transactionToRefund.from,
              props.relations.accounts[transactionToRefund.from?.userId],
            )}{' '}
            - {transactionToRefund.amount} dhs
          </Header>
        )}
        <div>
          <Form>
            <TextArea
              value={refundMotive}
              onChange={(e, { value }) => {
                setRefundMotive(value);
              }}
              placeholder={t('Motif du remboursement')}
            />
          </Form>
        </div>
      </DialogBox>
      {transactionToRefund && (
        <Confirm
          open={refundConfirmOpen}
          content={t(
            `Vous allez rembourser {{amount}} à {{destination}}. Confirmer ?`,
            {
              amount: transactionToRefund.amount,
              destination: renderPaymentAccount(
                transactionToRefund.from,
                props.relations.accounts[transactionToRefund.from?.userId],
              ),
            },
          )}
          onConfirm={submitRefund}
          onCancel={cancelRefund}
          confirmButton={{
            content: t('Confirmer'),
            loading: creating,
            disabled: creating,
          }}
          cancelButton={t('Annuler')}
        />
      )}
      <CRUDManager
        // recordActions={[
        //   {
        //     render: ({ record }) =>
        //       record.status === 'REFUNDED' ? null : (
        //         <Button
        //           key="refund"
        //           primary
        //           inverted
        //           onClick={() => openRefundModal(record)}
        //         >
        //           {t('Rembourser')}
        //         </Button>
        //       ),
        //   },
        // ]}
        records={localSortedRecords}
        fetchStart={payload => {
          const payloadWithDefaultOrder = {
            ...payload,
            order: payload.order || 'createdAt DESC',
          };
          setCurrentOrder(payloadWithDefaultOrder.order);
          fetchStart(payloadWithDefaultOrder);
        }}
        fields={fieldsWithMetadataField}
        createStart={() => {}}
        creating={false}
        {...props}
      />
    </div>
  );
};

export default enhance(TransactionsManager);
