import React, { Component } from 'react';
import {
  Form,
  Radio,
  Button,
  Search,
  Dropdown,
  Input,
} from 'semantic-ui-react';
import { DateTimeInput } from 'semantic-ui-calendar-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import map from 'lodash/map';
import uniqBy from 'lodash/uniqBy';

import PlacesAutocompleteInput from '../../common/PlacesAutocompleteInput';
import colors from '../../../theme/colors';

const frequency = [
  { key: 'daily', text: 'JOUR(S)', value: 'DAILY' },
  { key: 'weekly', text: 'SEMAINE(S)', value: 'WEEKLY' },
  { key: 'monthly', text: 'MOIS', value: 'MONTHLY' },
];

const weekDays = {
  MON: 'L',
  TUE: 'M',
  WED: 'M',
  THU: 'J',
  FRI: 'V',
  SAT: 'S',
  SUN: 'D',
};

export default class TripForm extends Component {
  constructor(props) {
    super(props);
    const { trip } = props;
    this.state = {
      trip,
      driverResults: [],
      vehicleResults: [],
    };
  }

  componentDidMount() {
    const { fetchGroups } = this.props;
    fetchGroups();
  }

  toggleRecurrence = () => {
    const { trip, onChange } = this.props;
    const updatedTrip = {
      ...trip,
      recurrence: trip.recurrence ? null : { frequency: {}, days: [] },
    };
    onChange(updatedTrip);
  };

  handleSearchDriverChange = (e, { value }) => {
    const { drivers } = this.props;
    const driversList = [];
    drivers.map((d, i) =>
      driversList.push({
        ...d,
        title: `${d.firstname} ${d.lastname}`,
        description: d.phone,
        key: i,
        id: d.id,
      }),
    );
    if (value === '') {
      this.setState({ driverResults: driversList });
    } else {
      const filteredDrivers = driversList.filter(d =>
        `${d.firstname} ${d.lastname} ${d.phone}`
          .toLowerCase()
          .includes(value.toLowerCase()),
      );
      this.setState({ driverResults: filteredDrivers });
    }
  };

  handleDriverResultSelect = (e, { result }) => {
    const { onChange, trip } = this.props;
    const updatedTrip = { ...trip, driverId: result.id };
    onChange(updatedTrip);
  };

  handleSearchVehicleChange = (e, { value }) => {
    const { vehicles } = this.props;

    const vehiclesList = [];
    vehicles.map((v, i) =>
      vehiclesList.push({
        ...v,
        title: `${v.carNumber}`,
        description: `${v.capacity} places`,
        key: i,
        id: v.id,
      }),
    );
    if (value === '') {
      this.setState({ vehicleResults: vehiclesList });
    } else {
      const filteredVehicles = vehiclesList.filter(d =>
        `${d.carNumber}`.toLowerCase().includes(value.toLowerCase()),
      );
      this.setState({ vehicleResults: filteredVehicles });
    }
  };

  handleVehicleResultSelect = (e, { result }) => {
    const { onChange, trip } = this.props;
    const updatedTrip = { ...trip, vehicleId: result.id };
    onChange(updatedTrip);
  };

  handleDepartureTimeChange = (e, { name, value }) => {
    const { onChange, trip } = this.props;
    const updatedTrip = {
      ...trip,
      departureTime: moment(value, 'DD-MM-YYYY HH:mm').toDate(),
    };
    onChange(updatedTrip);
  };

  handleChangeEndDate = (e, { name, value }) => {
    const { onChange, trip } = this.props;
    const updatedTrip = {
      ...trip,
      endDate: moment(value, 'DD-MM-YYYY HH:mm'),
    };
    onChange(updatedTrip);
  };

  handleChangeGroups = (e, { value }) => {
    const { onChange, trip } = this.props;
    const updatedTrip = {
      ...trip,
      groups: value,
    };
    onChange(updatedTrip);
  };

  handleChangeFolder = (e, { value }) => {
    const { onChange, trip } = this.props;
    const updatedTrip = {
      ...trip,
      folder: value,
    };
    onChange(updatedTrip);
  };

  toggleDay(day) {
    const { onChange, trip } = this.props;
    const updatedTrip = { ...trip };
    if (trip.recurrence.days.includes(day)) {
      updatedTrip.recurrence.days = trip.recurrence.days.filter(d => d !== day);
    } else {
      updatedTrip.recurrence.days = trip.recurrence.days.concat(day);
    }
    this.setState({ trip: updatedTrip });
    onChange(updatedTrip);
  }

  render() {
    const { driverResults, vehicleResults } = this.state;
    const { onChange, trip, groups } = this.props;
    return (
      <Form>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Conducteur</label>
            <Search
              onResultSelect={this.handleDriverResultSelect}
              onSearchChange={this.handleSearchDriverChange}
              results={driverResults}
              defaultValue={`${trip.driver ? trip.driver.firstname + ' ' : ''}${
                trip.driver ? trip.driver.lastname : ''
                }`}
              size="tiny"
            />
          </Form.Field>
          <Form.Field>
            <label>Véhicule</label>
            <Search
              onResultSelect={this.handleVehicleResultSelect}
              onSearchChange={this.handleSearchVehicleChange}
              results={vehicleResults}
              size="tiny"
              defaultValue={`${trip.vehicle ? trip.vehicle.carNumber : ''}`}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Date et heure de départ</label>
            <DateTimeInput
              closable
              value={
                trip.departureTime
                  ? moment(trip.departureTime).format('DD-MM-YYYY HH:mm')
                  : ''
              }
              onChange={this.handleDepartureTimeChange}
            />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Lieu de départ</label>
            <PlacesAutocompleteInput
              onChange={place => {
                onChange({
                  ...trip,
                  from: place,
                });
              }}
              value={trip.from && trip.from.name}
            />
          </Form.Field>
          <Form.Field>
            <label>Lieu d'arrivée</label>
            <PlacesAutocompleteInput
              onChange={place => {
                onChange({
                  ...trip,
                  to: place,
                });
              }}
              value={trip.to && trip.to.name}
            />
          </Form.Field>
        </Form.Group>
        <div>
          <Form.Field style={styles.row}>
            <div style={styles.rowSpaced}>
              <label style={{ marginBottom: 3 }}>Voyage périodique</label>
              <label style={styles.warning}>
                Ce voyage se répéte périodiquement
              </label>
            </div>
            <Radio
              toggle
              checked={!!trip.recurrence}
              onChange={this.toggleRecurrence}
            />
          </Form.Field>
        </div>
        {trip.recurrence && (
          <div style={styles.trip}>
            <Form.Group widths="one">
              <Form.Field>
                <label>Répéter toutes les</label>
                <div style={styles.row}>
                  <Form.Input
                    onChange={e => {
                      const updatedTrip = {
                        ...trip,
                        recurrence: {
                          ...trip.recurrence,
                          frequency: {
                            ...trip.recurrence.frequency,
                            scale: Number(e.target.value),
                          },
                        },
                      };
                      onChange(updatedTrip);
                    }}
                    style={{ width: 50 }}
                    value={
                      trip.recurrence ? trip.recurrence.frequency.scale : ''
                    }
                  />
                  <Form.Select
                    onChange={(e, data) => {
                      const updatedTrip = {
                        ...trip,
                        recurrence: {
                          ...trip.recurrence,
                          frequency: {
                            ...trip.recurrence.frequency,
                            period: data.value,
                          },
                        },
                      };
                      onChange(updatedTrip);
                    }}
                    options={frequency}
                    style={{ marginLeft: 20, width: 100 }}
                    value={
                      trip.recurrence ? trip.recurrence.frequency.period : ''
                    }
                  />
                </div>
              </Form.Field>
              <Form.Field>
                <label>Répéter le</label>
                {map(weekDays, (label, value) => {
                  const active = trip.recurrence.days.includes(value);
                  return (
                    <Button
                      key={value}
                      style={{
                        backgroundColor: active
                          ? colors.LIGHT_BLUE
                          : colors.WHITE,
                        border: '1px solid #e9eff4',
                        color: active ? colors.WHITE : colors.BLACK,
                      }}
                      active={active}
                      onClick={() => this.toggleDay(value)}
                    >
                      {label}
                    </Button>
                  );
                })}
              </Form.Field>
            </Form.Group>
            <Form.Field>
              <label>Se termine</label>
              <DateTimeInput
                closable
                onChange={this.handleChangeEndDate}
                value={
                  trip.endDate &&
                  moment(trip.endDate).format('DD-MM-YYYY HH:mm')
                }
              />
            </Form.Field>
          </div>
        )}
        <div>
          <Form.Field>
            <label>Groupes</label>
            <Dropdown
              placeholder={'Groupes'}
              fluid
              search
              selection
              multiple
              options={uniqBy(
                map(groups, group => ({
                  key: group._id,
                  value: group._id,
                  text: group.name,
                })).concat(
                  // This is so that deleted groups still appear with their ids
                  (trip.groups || []).map(groupId => ({
                    key: groupId,
                    value: groupId,
                    text: groupId,
                  })),
                ),
                'key',
              )}
              value={trip.groups || []}
              onChange={this.handleChangeGroups}
            />
          </Form.Field>
          <Form.Field>
            <label>Dossier</label>
            <Input
              value={trip.folder || ''}
              onChange={this.handleChangeFolder}
            />
          </Form.Field>
        </div>
      </Form>
    );
  }
}

TripForm.defaultProps = {
  trip: {},
};

TripForm.propTypes = {
  trip: PropTypes.shape({
    departureTime: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    endDate: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.instanceOf(Date),
    ]),
    driver: PropTypes.shape({
      fistname: PropTypes.string,
      lastname: PropTypes.string,
      availability: PropTypes.bool,
      phone: PropTypes.string,
    }),
    vehicle: PropTypes.shape({
      carNumber: PropTypes.string,
      capacity: PropTypes.number,
    }),
    from: PropTypes.shape({
      name: PropTypes.string,
      coords: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    }),
    to: PropTypes.shape({
      name: PropTypes.string,
      coords: PropTypes.shape({
        lat: PropTypes.number,
        lng: PropTypes.number,
      }),
    }),
  }).isRequired,
  drivers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      firstname: PropTypes.string,
      lastname: PropTypes.string,
      phone: PropTypes.string,
    }),
  ).isRequired,
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      capacity: PropTypes.number,
      carNumber: PropTypes.string,
    }),
  ).isRequired,
  groups: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
  ),
  fetchGroups: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const styles = {
  buttonRadio: {
    display: 'flex',
    margin: '10px',
  },
  warning: {
    fontSize: 12,
    color: colors.SUBTITLE_LABEL,
  },
  frequency: {
    display: 'flex',
    flexDirection: 'column',
    width: 300,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  rowSpaced: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-around',
  },
  stops: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
