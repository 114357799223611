import React, { Component } from 'react';
import { Form, Input, Button } from 'semantic-ui-react';
import withi18n from 'weego-common/src/hoc/i18n';

import colors from '../../theme/colors';

class AuthConfirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSending: false,
    };
  }

  handleValidationChange = e => {
    const { getNumberValidation } = this.props;
    getNumberValidation(e.target.value);
  };

  validate = () => {
    const { confirmation } = this.props;
    this.setState({
      isSending: true,
    });
    confirmation(true);
  };
  render() {
    const { t } = this.props;
    return (
      <div style={styles.container}>
        <Form>
          <Form.Field>
            <Input
              placeholder={t('Code de vérification par SMS.')}
              size="large"
              onChange={this.handleValidationChange.bind(this)}
            />
          </Form.Field>
          <Button
            style={styles.buttonConfirm}
            onClick={this.validate.bind(this)}
            primary
            size="large"
          >
            {t('Valider')}
          </Button>
        </Form>
      </div>
    );
  }
}

const styles = {
  container: { width: '100%' },
  confirmation: { color: colors.LOGIN_GREY },
  buttonConfirm: {
    width: '100%',
    background: colors.PRIMARY,
    marginTop: 10,
  },
};

export default withi18n('auth')(AuthConfirm);
