import { connect } from 'react-redux';
import compose from 'recompose/compose';

import Notifications from './Notifications';
import notificationActions from '../../actions/notification';

const mapStateToProps = state => ({
  fetchingNotifications: state.notification.loading,
  notificationsList: state.notification.notificationsList,
});

const mapDispatchToProps = dispatch => ({
  requestNotificationsList: compose(
    dispatch,
    notificationActions.requestNotificationsList,
  ),
});

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Notifications);
