import React, { Component } from 'react';
import {
  Table,
  Button,
  TableHeader,
  TableHeaderCell,
  TableBody,
  TableCell,
  TableRow,
  Form,
  Icon,
  Input,
  Label,
} from 'semantic-ui-react';
import { Polyline, Marker } from 'react-google-maps';
import update from 'immutability-helper';

import PlacesAutoCompleteInput from '../../common/PlacesAutocompleteInput';
import Map from '../../common/Map';
import departurePin from '../../../assets/images/departure-pin.svg';
import arrivalPin from '../../../assets/images/arrival-pin.svg';

import { getCenter, getPathLength } from 'geolib';

export default class Stops extends Component {
  state = {
    stopItem: {
      name: '',
      coords: {},
    },
    _trip: {},
    index: null,
    isNewCoordinate: false,
    center: {},
  };

  _normalizeGeoKeys = coords => ({
    lat: coords.latitude || coords.lat,
    lng: coords.longitude || coords.lng,
  });

  _keyByIndex = (object, i) => ({
    ...object,
    key: i,
  });

  componentWillMount() {
    const { trip } = this.props;
    this.setState(
      {
        _trip: {
          ...trip,
        },
      },
      () => {
        if (!trip.path) {
          this.resetPathToStops();
        }
      },
    );
  }

  resetPathToStops = () => {
    const { onChange } = this.props;
    const { _trip } = this.state;
    const tripStops = [_trip.from, ...(_trip.stops || []), _trip.to];
    this.setState(
      {
        _trip: {
          ..._trip,
          path: null,
        },
      },
      () => {
        this.setState(
          {
            _trip: {
              ..._trip,
              path: tripStops.map(stop => this._normalizeGeoKeys(stop.coords)),
            },
          },
          () => onChange(this.state._trip),
        );
      },
    );
  };

  addStopItem = () => {
    const { stopItem, _trip, index } = this.state;
    const { onChange } = this.props;
    if (index != null) {
      _trip.stops &&
        _trip.stops.forEach((_t, key) => {
          if (key === index) {
            this.setState(
              prevState => {
                const stops = update(prevState._trip.stops, {
                  [key]: { $set: stopItem },
                });
                return {
                  _trip: {
                    ...prevState._trip,
                    stops,
                  },
                  stopItem: { name: '', coords: {} },
                  index: null,
                };
              },
              () => onChange(this.state._trip),
            );
            this.setState({
              index: null,
            });
          }
        });
    } else {
      this.setState(
        prevState => ({
          _trip: {
            ...prevState._trip,
            stops: (prevState._trip.stops || []).concat(stopItem),
          },
          stopItem: { name: '', coords: {} },
          index: null,
          isNewCoordinate: false,
        }),
        () => {
          onChange(this.state._trip);
        },
      );
    }
  };

  onChangeStops = (address, latLng, key) => {
    const { onChange } = this.props;
    const { _trip } = this.state;
    const stop = { name: address, coords: latLng };
    this.setState(
      {
        stopItem: stop,
        index: key,
      },
      () => onChange(_trip),
    );
  };

  onDeleteStops = key => {
    const { onChange } = this.props;
    this.setState(
      prevState => ({
        _trip: {
          ...prevState._trip,
          stops: update(prevState._trip.stops, {
            $splice: [[key, 1]],
          }),
        },
      }),
      () => onChange(this.state._trip),
    );
  };

  addToPath(position, coordinates) {
    const { onChange } = this.props;
    const { _trip } = this.state;
    const newPath = [..._trip.path];
    newPath.splice(position, 0, coordinates);
    this.setState(
      {
        _trip: {
          ..._trip,
          path: newPath,
        },
      },
      () => onChange(this.state._trip),
    );
  }

  updatePathAt(position, newCoordinates) {
    const { onChange } = this.props;
    const { _trip } = this.state;
    this.setState(
      {
        _trip: {
          ..._trip,
          path: _trip.path.map((c, i) => (i === position ? newCoordinates : c)),
        },
      },
      () => onChange(this.state._trip),
    );
  }

  removePathAt(position) {
    const { onChange } = this.props;
    const { _trip } = this.state;
    this.setState(
      {
        _trip: {
          ..._trip,
          path: _trip.path.filter((c, i) => (i === position ? false : true)),
        },
      },
      onChange(this.state._trip),
    );
  }

  setupPathListeners = polyline => {
    if (!polyline) {
      return;
    }
    const { event } = window.google.maps;
    const path = polyline.getPath();
    this.setState({ gmapsPath: path });
    event.addListener(path, 'insert_at', i => {
      const newItem = path.getAt(i);
      this.addToPath(i, { latitude: newItem.lat(), longitude: newItem.lng() });
    });
    event.addListener(path, 'remove_at', i => {
      this.removePathAt(i);
    });
    event.addListener(path, 'set_at', i => {
      const updatedItem = path.getAt(i);
      this.updatePathAt(i, { lat: updatedItem.lat(), lng: updatedItem.lng() });
    });
  };

  onDefineStop = event => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    this.setState(prevState => ({
      stopItem: {
        coords: { lat, lng },
      },
      isNewCoordinate: true,
    }));
  };

  newNamePlace = e => {
    const newNamePlace = e.target.value;
    this.setState(
      prevState => ({
        stopItem: {
          ...prevState.stopItem,
          name: newNamePlace,
        },
      }),
      () => console.log(this.state.stopItem),
    );
  };

  componentDidMount() {
    const { from, to, stops } = this.props.trip;
    let stopCoordinates = [from, to];

    if (stops) {
      stopCoordinates.push(...stops);
    }
    stopCoordinates = stopCoordinates.map(stop => stop.coords);

    const d = getCenter(stopCoordinates);
    const distance = getPathLength(stopCoordinates);

    //google full maps (zoom=0|1) ratio
    const R = 156543.03392;
    /**
     * We compute the meterPerPx according to the distance and divide it by the height and width of the maps
     */
    const meterPxZ1 = distance / 300;
    const meterPxZ2 = distance / 590;

    let alpha = Math.cos((Number.parseFloat(d.latitude) * Math.PI) / 180);

    //We then subtract -1 for normalisation of the zoom value
    const z1 = Math.round(Math.log2((R * alpha) / meterPxZ1)) - 1;
    const z2 = Math.round(Math.log2((R * alpha) / meterPxZ2)) - 1;

    const center = {
      lat: Number.parseFloat(d.latitude),
      lng: Number.parseFloat(d.longitude),
    };
    this.setState({
      center: center,
      zoom: z1 > z2 ? z1 : z2,
    });
  }

  render() {
    const { trip } = this.props;
    const { stopItem, _trip, isNewCoordinate } = this.state;
    return (
      <div>
        <div>
          <Form>
            <Form.Group>
              <Form.Field>
                {isNewCoordinate ? (
                  <div>
                    <Input
                      placeholder="New Place"
                      onChange={this.newNamePlace}
                      value={stopItem && stopItem.name}
                    />
                    <br />
                    <Label>
                      <Label basic color="blue" pointing="right">
                        Latitude
                      </Label>
                      {stopItem.coords && stopItem.coords.lat}
                    </Label>
                    <Label>
                      <Label basic color="blue" pointing="right">
                        Longitude
                      </Label>
                      {stopItem.coords && stopItem.coords.lng}
                    </Label>
                  </div>
                ) : (
                  <PlacesAutoCompleteInput
                    value={stopItem.name}
                    onChange={address => {
                      this.setState({
                        stopItem: address,
                      });
                    }}
                  />
                )}
              </Form.Field>
              <Form.Field>
                {isNewCoordinate ? (
                  <Button
                    primary
                    style={{ borderRadius: 50 }}
                    onClick={event => this.setState({ isNewCoordinate: false })}
                  >
                    Valider
                  </Button>
                ) : (
                  ''
                )}
                {stopItem && stopItem.name ? (
                  <Button
                    secondary
                    onClick={this.addStopItem}
                    style={{ borderRadius: 50 }}
                  >
                    {this.state.index != null ? (
                      'Valider'
                    ) : (
                      <Icon name="plus" />
                    )}
                  </Button>
                ) : (
                  ''
                )}
              </Form.Field>
            </Form.Group>
          </Form>
        </div>
        <div style={styles.tableStops}>
          <Table celled style={{ margin: 30 }}>
            <TableHeader>
              <TableHeaderCell>
                Arrêt(s) voyage de : {trip.from.name} to {trip.to.name}
              </TableHeaderCell>
              <TableHeaderCell />
              <TableHeaderCell />
            </TableHeader>
            <TableBody>
              {_trip.stops &&
                _trip.stops.map((t, key) => {
                  return (
                    <TableRow key={key}>
                      <TableCell>{t.name}</TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            this.onChangeStops(t.name, t.coords, key)
                          }
                          secondary
                        >
                          Modifier
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Button
                          onClick={() =>
                            this.onDeleteStops(key, t.name, t.coords)
                          }
                          negative
                        >
                          Supprimer
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </div>
        <div style={{ height: 300 }}>
          <Map
            onClick={event => this.onDefineStop(event)}
            zoom={this.state.zoom}
            center={this.state.center}
          >
            {isNewCoordinate && stopItem ? (
              <Marker
                position={stopItem && stopItem.coords}
                defaultTitle="Ajout de nouvel arrêt"
              />
            ) : (
              ''
            )}
            <Marker
              position={trip.from.coords}
              defaultTitle="Terminus de départ"
              icon={{
                url: departurePin,
              }}
            />
            <Marker
              position={trip.to.coords}
              defaultTitle="Terminus de départ"
              icon={{
                url: arrivalPin,
              }}
            />
            {_trip && _trip.path && (
              <Polyline
                key={_trip.id}
                defaultPath={_trip.path
                  .map(this._normalizeGeoKeys)
                  .map(this._keyByIndex)}
                editable={true}
                ref={this.setupPathListeners}
              />
            )}
          </Map>
          <Button
            primary
            onClick={this.resetPathToStops}
            style={styles.resetPathButton}
          >
            Réinitaliser le chemin
          </Button>
        </div>
      </div>
    );
  }
}
const styles = {
  tableStops: {
    display: 'flex',
    alignContent: 'space-between',
  },
  resetPathButton: {
    display: 'block',
    margin: 'auto',
    textAlign: 'center',
    marginTop: 10,
  },
};
