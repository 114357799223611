import React, { Component } from 'react';
import { List } from 'semantic-ui-react';
import PropTypes from 'prop-types';

import Trip from './Trip';

export default class Trips extends Component {
  render() {
    const { trips, withoutNotifyButton } = this.props;
    return (
      <List
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflowY: 'auto',
          overflowX: 'auto',
          padding: 5,
        }}
        horizontal
      >
        {trips &&
          trips.map((t, i) => (
            <List.Item key={i}>
              <Trip withoutNotifyButton={withoutNotifyButton} trip={t} />
            </List.Item>
          ))}
      </List>
    );
  }
}

Trips.defaultProps = {
  withoutNotifyButton: false,
};

Trips.propTypes = {
  trips: PropTypes.arrayOf(Trip.propTypes.trip),
  withoutNotifyButton: PropTypes.bool,
};
