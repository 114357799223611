import React, { memo } from 'react';
import { Card, Image, Button, Popup, Icon } from 'semantic-ui-react';
import moment from 'moment-timezone';
import { ReactSVG } from 'react-svg';
import PropTypes from 'prop-types';
import sumBy from 'lodash/sumBy';

import arrowCircleRight from '../../../assets/images/arrow-circle-right.svg';
import tripPropType from '../tripPropType';
import colors from '../../../theme/colors.json';
import collaboratorsIcon from '../../../assets/icons/collaborators.svg';
import distanceIcon from '../../../assets/icons/distance.svg';
import flagIcon from '../../../assets/icons/flag.svg';
import clockIcon from '../../../assets/icons/clock.svg';
import calendarIcon from '../../../assets/icons/calendar.svg';
import vanImage from '../../../assets/images/van/van.png';

import './TripSummary.css';
import tripPassengerCount from '../../../utils/tripPassengerCount';
import withi18n from 'weego-common/src/hoc/i18n';

const TripSummary = memo(function TripSummary({
  trip,
  legs = [],
  focused,
  minimized = false,
  onClick,
  onDetailsClick,
  onNotificationsClick,
  t,
}) {
  const closestNextStop = getClosestNextStop(trip);
  const totalDistance = sumBy(trip.legs || legs, leg => leg.distance.value);
  return (
    <Card
      className="trip-summary"
      onClick={onClick && (() => onClick(trip))}
      style={focused ? styles.focusedCard : null}
    >
      <Card.Content>
        <div style={styles.header}>
          <div>
            <Button onClick={() => onDetailsClick(trip)}>{t('Détails')}</Button>
            <Button onClick={() => onNotificationsClick(trip)} icon>
              <Icon name="bell outline" />
            </Button>
          </div>
        </div>
        <div style={styles.driverInfosContainer}>
          <Image src={vanImage} />
          <div style={styles.driverInfos}>
            <div style={styles.driverNamePhone}>
              <span style={styles.driverName}>
                {trip.driver?.firstname}
                {trip.driver && !trip.driver.available ? (
                  <Popup
                    content={t("Ce conducteur n'est pas disponible")}
                    position="bottom center"
                    trigger={<Icon name="warning sign" color="yellow" />}
                  />
                ) : null}
              </span>
              <span style={styles.driverPhone}>{trip.driver?.phone}</span>
            </div>
            <span style={{ color: colors.DARK_700 }}>
              {trip.vehicle?.carLabel || trip.vehicle?.carNumber}
              {trip.vehicle && !trip.vehicle.available ? (
                <Popup
                  content={t("Ce véhicule n'est pas disponible")}
                  position="bottom center"
                  trigger={<Icon name="warning sign" color="yellow" />}
                />
              ) : null}
            </span>
          </div>
        </div>
      </Card.Content>
      {!minimized && (
        <div style={styles.routeContainer}>
          <div style={styles.dotsIndicators}>
            <div style={styles.stepIconContainer}>
              <div style={styles.currentStepIcon}>
                <div style={styles.innerCurrentStep}></div>
              </div>
            </div>
            <div class="dashed-line"></div>
            <div style={styles.stepIconContainer}>
              <div style={styles.lastStepIcon}></div>
            </div>
          </div>
          <div style={styles.stepContentContainer}>
            <div style={styles.topStepContent}>
              <span style={styles.addressLabel}>{trip.from?.name}.</span>
              <div style={styles.timeContainer}>
                <ReactSVG
                  beforeInjection={svg => {
                    svg.setAttribute('display', 'flex');
                  }}
                  src={clockIcon}
                />
                <span>
                  {trip.from?.maxDate
                    ? moment(trip.from.maxDate).format('HH:mm')
                    : moment(trip.departureTime).format('HH:mm')}
                </span>
              </div>
            </div>
            <div style={styles.bottomStepContent}>
              <span style={styles.addressLabel}>{trip.to?.name}.</span>
              <div style={styles.timeContainer}>
                <ReactSVG
                  beforeInjection={svg => {
                    svg.setAttribute('display', 'flex');
                  }}
                  src={clockIcon}
                />
                <span>
                  {trip.to?.maxDate
                    ? moment(trip.to.maxDate).format('HH:mm')
                    : 'Inconnue'}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
      {!minimized && (
        <Card.Content extra>
          {(trip.legs || legs)?.length ? (
            <div style={styles.info}>
              <ReactSVG
                beforeInjection={svg => {
                  svg.setAttribute('display', 'flex');
                  svg.setAttribute('width', '24');
                  svg.setAttribute('height', '24');
                }}
                src={flagIcon}
              />
              <span style={styles.infoValue}>{trip.stops?.length ?? 0}</span>
              <span style={styles.infoLabel}>{`${t('station')}${
                trip.stops?.length <= 1 ? '' : 's'
              }`}</span>
            </div>
          ) : null}
          {(trip.legs || legs)?.length ? (
            <div style={styles.info}>
              <ReactSVG
                beforeInjection={svg => {
                  svg.setAttribute('display', 'flex');
                  svg.setAttribute('width', '24');
                  svg.setAttribute('height', '24');
                }}
                src={distanceIcon}
              />
              <span style={styles.infoValue}>
                {Math.round(totalDistance / 1000)}
              </span>
              <span style={styles.infoLabel}>kms</span>
            </div>
          ) : null}
          {trip.demandIds && trip.demandIds.length ? (
            <div style={styles.info}>
              <ReactSVG
                beforeInjection={svg => {
                  svg.setAttribute('display', 'flex');
                  svg.setAttribute('width', '24');
                  svg.setAttribute('height', '24');
                }}
                src={collaboratorsIcon}
              />
              <span style={styles.infoValue}>{tripPassengerCount(trip)}</span>
              <span style={styles.infoLabel}>{`${t('collaborateur')}${
                tripPassengerCount(trip) <= 1 ? '' : 's'
              }`}</span>
            </div>
          ) : null}
          <div style={styles.info}>
            <ReactSVG
              beforeInjection={svg => {
                svg.setAttribute('display', 'flex');
                svg.setAttribute('width', '24');
                svg.setAttribute('height', '24');
              }}
              src={calendarIcon}
            />
            <span style={styles.infoLabel}>
              {moment(trip.departureTime).format('DD MMMM YYYY')}
            </span>
          </div>

          {!minimized && <span style={styles.tripId}>{trip.id}</span>}
        </Card.Content>
      )}
      {minimized && closestNextStop && (
        <Card.Content extra style={styles.closestNextStop}>
          <div style={styles.closestNextStopNameContainer}>
            <Image src={arrowCircleRight} />
            <span style={styles.closestNextStopName}>
              {closestNextStop.name}
            </span>
          </div>
          <div>{moment(closestNextStop.maxDate).format('HH:mm')}</div>
        </Card.Content>
      )}
    </Card>
  );
});

const styles = {
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  vehicle: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  vehicleLabel: {
    color: colors.GREY_TEXT,
    paddingLeft: 10,
  },
  driver: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
  currentStepIcon: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: colors.APRICOT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  innerCurrentStep: {
    width: 4,
    height: 4,
    borderRadius: '50%',
    backgroundColor: colors.WHITE,
  },
  lastStepIcon: {
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: colors.PRIMARY,
  },
  pastStepIcon: {
    width: 18,
    height: 18,
    borderRadius: '50%',
    backgroundColor: colors.GREY,
    border: `solid 4.5px #F8F8F8`,
  },
  info: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    color: colors.PRIMARY,
    borderRadius: 5,
    marginTop: 5,
    marginBottom: 5,
  },
  infoLabel: {
    fontSize: 12,
    color: colors.DARK_800,
    marginLeft: 5,
  },
  infoValue: {
    fontSize: 12,
    fontWeight: 'bold',
    color: colors.DARK_800,
    marginLeft: 5,
  },
  focusedCard: {
    border: 'solid 3px',
    borderColor: colors.APRICOT,
  },
  closestNextStop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colors.DARK_GREY,
    fontWeight: 'bold',
  },
  closestNextStopNameContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  closestNextStopName: {
    marginLeft: 5,
  },
  tripId: {
    fontSize: 10,
    color: colors.DARK_GREY,
    float: 'right',
  },
  routeContainer: {
    marginLeft: 10,
    marginRight: 10,
    padding: 10,
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: 'rgba(34, 15, 70, 0.03)',
    borderRadius: 6,
  },
  dotsIndicators: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  stepIconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  stepContentContainer: {
    marginLeft: 5,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: -4,
    marginBottom: -4,
  },
  topStepContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    fontSize: 12,
    color: colors.DARK_800,
    fontWeight: '500',
  },
  bottomStepContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    fontSize: 12,
    color: colors.DARK_800,
    fontWeight: '500',
  },
  addressLabel: { display: 'flex', flex: 0.8 },
  timeContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 0.2,
  },
  driverInfosContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  driverInfos: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginLeft: 5,
    fontSize: 12,
    fontWeight: '500',
  },
  driverNamePhone: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  driverName: {
    fontWeight: '500',
    color: colors.DARK_800,
    display: 'flex',
    flex: 1,
  },
  driverPhone: {
    fontWeight: '500',
    color: colors.DARK_800,
    display: 'flex',
    flex: 1,
  },
};

TripSummary.defaultProps = {
  onClick: undefined,
};

TripSummary.propTypes = {
  trip: tripPropType,
  focused: PropTypes.bool,
  onClick: PropTypes.func,
  onDetailsClick: PropTypes.func.isRequired,
  onNotificationsClick: PropTypes.func.isRequired,
};

const getClosestNextStop = trip => {
  const allStops = [trip.from, ...(trip.stops || []), trip.to];
  const now = moment();
  return allStops.find(stop => {
    const tripDepartureTime = moment(trip.departureTime);
    const stopTime = moment(stop.maxDate).set({
      year: tripDepartureTime.year(),
      month: tripDepartureTime.month(),
      date: tripDepartureTime.date(),
    });
    return stopTime.isAfter(now);
  });
};

export default withi18n('dashboard')(TripSummary);
