import React, { Component } from 'react';
import withi18n from 'weego-common/src/hoc/i18n';

class AuthText extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <div style={styles.text1Container}>
          <label style={styles.text1}>{t('Se connecter à Weego')}</label>
        </div>
        <div>
          <label style={styles.text2}>
            {t(
              "Veuillez entrer vos information d'indentification pour continuer",
            )}
          </label>
        </div>
      </div>
    );
  }
}
const styles = {
  text1: {
    fontSize: 20,
  },
  text2: { fontSize: 14 },
  text1Container: {
    marginBottom: 50,
  },
};

export default withi18n('auth')(AuthText);
